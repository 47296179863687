import React, { useState } from "react";
// Prompt: Could I have a dot points of 4 positive things about a German Shepherd. Each dot point should be no longer than 23 words. You should have the tone of someone who likes to baby talk about dogs. The dot points should not be numbered. To give you some examples of the baby talk commonly used here are some words: heck, hecking, hawo, hencho, da, good boy, hooman, floofer, butt scratches, zoomies, toe beans, belly rubs, walkies, wurvv, snuggles, smooches, treats, pupper, snoot, fetch, noses, woofs, etc. These words should only be used if context appropriate and not too often. The positive facts should be based on real know traits of the dog breed. Add on a 5th dot point that follows all the same rules but is a negative fact, also based on a real trait of the dog breed, but with a positive tone. Use the format: ["point 1", "point 2"]
const dogBreeds = [
  {
    breed: "Golden Retriever",
    image: "/Golden-Retriever.jpg",
    Positive: ["Golden Retrievers are known for their friendly, loving personalities and make great family pets", "They're incredibly smart pupperinos and are easy to train for all sorts of tricks and skills", "These fluffy butt scratchies love their walkies and are always eager for outdoor adventures", "Golden Retrievers have a soft spot for belly rubs and snuggles, making them the ultimate comfort dogs", "Golden Retrievers love their treats and may have a tendency to overeat, but it just makes them even more huggable and snuggly!"],
  },
  {
    breed: "German Shepherd",
    image: "/German-Shepherd.jpg",
    Positive: ["German Shepherds are the ultimate good boy with their protective and loyal nature, they'll always have your back!",
    "They love to play fetch, run and have zoomies, providing endless entertainment for their hooman!",
    "These floofers love belly rubs and butt scratches, they'll never say no to a good snuggle session!",
    "German Shepherds are extremely intelligent and love to learn new things, making them a fun and obedient pup to train!",
    "Though they have a lot of energy, German Shepherds also love their snoozes and can be quite the lazy butt at times, but that's just part of their charm!"]
  },
  {
    breed: "Siberian Husky",
    image: "/Siberian-Husky.jpg",
    Positive: ["Our little floofers are born to run! Siberian Huskies were bred as sled dogs and love to run and play",
    "These pupperinos are the ultimate snugglers! They love nothing more than snuggling up for belly rubs and smooches",
    "Our wurvvy Siberian Huskies are known for their mischievous, playful personalities and love for zoomies and fetch",
    "These good boys have a strong pack instinct and love to be around their hooman and other dogs, they're social butterfly pups",
    "Our Siberian Huskies have a lot of energy and love to run, which means they require plenty of exercise and mental stimulation. But hey, it just means more walkies and playtime for our pupperinos! Woof woof!"]
  },
  {
    breed: "Italian Greyhound",
    image: "/Italian-Greyhound.jpg",
    Positive: ["Italian Greyhounds are little speedsters who love to run and play! They're always ready for a good game of fetch or to chase after their zoomies",
    "These pupperinos are delicate and graceful, making them the perfect lap dogs. They love nothing more than snuggles and belly rubs from their hooman",
    "They are incredibly affectionate and bond closely with their hooman. They make wonderful companions and are always ready for a good snuggle session",
    "These sweet floofers are intelligent and eager to please. They love to learn new tricks and will do anything for a treat and lots of praise from their hooman",
    "Italian Greyhounds are fragile and have delicate bones, so they need to be handled with care. But with a little extra love and protection, they'll thrive and be the best, most loving companion ever."]
  },
  {
    breed: "Bulldog",
    image: "/Bulldog.jpg",
    Positive: ["Bulldogs are fiercely loyal to their hoomans", "Their cute wrinkly faces and chunky bodies make them a delightful sight to behold", "They are great snuggle buddies and love to give smooches and butt scratches", "Bulldogs are a low-energy breed, making them perfect for a good belly rub and a cozy nap", "Bulldogs can have trouble breathing and over-heating, but just make sure they have plenty of water and shade, and they'll be as happy as a pup in a belly-rub pile"]
  },
  {
    breed: "Poodle",
    image: "/Poodle.jpg",
    Positive: ["Poodles are highly intelligent and love to learn new tricks, perfect for playtime and training sessions", "They come in various sizes, making them suitable for apartments and big houses alike", "Their curly fur is hypoallergenic, making them a great choice for people with allergies", "Poodles have a fun-loving and energetic personality, they love to play fetch and go on walks", "Poodles require regular grooming to maintain their fancy hairdo, but with a little extra love and care, they will always look paw-some"]
  },
  {
    breed: "Chihuahua",
    image: "/Chihuahua.jpg",
    Positive: ["Chihuahuas are tiny pups with big personalities, they make excellent lap dogs", "They are fiercely loyal to their hoomans and love to give smooches and snuggles", "Chihuahuas are very adaptable and can live in small apartments or big houses, making them a great pet for city-dwellers", "These pups have a lot of energy and love to play, they're perfect for playing fetch or going on walkies", "Chihuahuas can be a little yappy at times, but with proper training and socialization, they will become the best and quietest lap dog ever"]
  },
  {
    breed: "Border Collie",
    image: "/Border-Collie.jpg",
    Positive: ["Border Collies are da smartest pupper around, with their quick minds and eagerness to please.",
    "These fur babies love to play fetch and go for walkies, burning off their energy and getting plenty of exercise.",
    "Border Collies have such affectionate snoots, always ready for snuggles and belly rubs!",
    "They have a strong instinct to herd, making them perfect for agility and obedience training.",
    "Border Collies may have a tendency to get a little too excited and nippy, but with proper training they become the bestest good boy around!"]
  },
  {
    breed: "Dachshund",
    image: "/Dachshund.jpg",
    Positive: ["Dachshunds are known for their hecking strong personalities, they may be small but they have the heart of a lion.",
    "Dachshunds are great snuggle buddies, they love nothing more than curling up with their favorite person for some smooches and belly rubs.",
    "Dachshunds have a love for adventure and enjoy going on walkies and playing fetch, making them great playmates for the whole family.",
    "Dachshunds are known for their sausage shape, making them easy to carry and perfect for traveling, they love a good adventure!",
    "Dachshunds can be prone to back issues, but with proper exercise and care they can live a long and happy life full of zoomies"]
  },
  {
    breed: "Airedale Terrier",
    image: "/Airedale-Terrier.jpg",
    Positive: ["Airedale Terriers are known for their intelligence and trainability, they love learning new tricks and pleasing their humans.",
    "Airedale Terriers have a high energy level and love going on walkies and playing fetch, they are great exercise partners.",
    "These pupper dogs are protective of their families and make excellent watch dogs, always on the lookout for any potential threats.",
    "Airedale Terriers have a strong and confident personality, they are not afraid to stand up for themselves and their loved ones.",
    "Airedale Terriers can be quite independent and stubborn at times, but with proper training and leadership, they make fantastic and loyal companions."]
  },
  {
    breed: "French Bulldog",
    image: "/French-Bulldog.jpg",
    Positive: ["Frenchies are hecking adorable with their wrinkly butts and short snoots",
    "They love to give smooches and snuggles, and will always be by your side for belly rubs",
    "Frenchies are tough little pups with a big personality, they love to play and have zoomies in the backyard",
    "These doggies are so smart and love to learn, they'll even do anything for a treat or two",
    "While they can be a little stubborn at times, they are just showing their strong personalities and it just makes them even more loveable"]
  },
  {
    breed: "Labrador Retriever",
    image: "/Labrador-Retriever.jpg",
    Positive: ["Labrador Retrievers are known for their loveable and friendly personalities, they are always eager to make new friends and give lots of kisses.",
    "Labrador Retrievers are highly intelligent and easily trainable, making them great companions for families and individuals alike.",
    "These pupper dogs have an endless supply of energy and love playing fetch and going on walkies, they are great for active individuals.",
    "Labrador Retrievers are natural swimmers and love being in the water, making them great partners for water activities.",
    "Labrador Retrievers have a tendency to become overweight if not given enough exercise and proper diet, but with proper care they can live a long and healthy life. (Oih nio, someone eats a bit too much)"]
  },
  {
    breed: "Borzoi",
    image: "/Borzoi.jpg",
    Positive: ["Borzois are gentle giants with their soft, silky fur and long legs making them perfect for snuggles and belly rubs!", "These pooches are bred for speed, making them great for a game of fetch or a good run with their zoomies!", "These good boys have a great sense of smell and love to use their noses to explore the world around them, making them great for walks and hikes!", "Borzois are loyal and affectionate dogs that love to be close to their human families and will always be there for a good smooch or a butt scratch!", "Though they are big dogs, they are not the best at obedience training and may be difficult to control at times, but with patience and love they will always be the wurvv of your heart!"]
  },
  {
    breed: "Beagle",
    image: "/Beagle.jpg",
    Positive: ["Beagles are the ultimate sniffer dogs with their strong sense of smell and love for adventure, making walks and hikes even more exciting!", "These pups are known for their sweet and playful personalities, always eager for a game of fetch or a belly rub!", "Beagles are highly social and love being around their human families, making them great companions for snuggles and smooches!", "These good boys have a tendency to follow their nose, so it's important to always keep them on a leash or in a fenced area for their safety!", "Though they are known for their strong will, with patience and positive reinforcement training, they will be the best little butt scratcher you could ever ask for!"]
  },
  {
    breed: "Australian Shepherd",
    image: "/Australian-Shepherd.jpg",
    Positive: ["Australian Shepherds are highly intelligent and energetic dogs, making them great for walks, runs, and games of fetch!", "These pups love to please their human families and are eager to learn new tricks, making training a fun bonding experience!", "Australian Shepherds are highly protective of their human families and make great watchdogs, always on the lookout for potential danger!", "These good boys have a strong herding instinct and love to chase after anything that moves, making them great for farm work or other outdoor activities!", "Though they have a lot of energy, with proper exercise and training, they will be the perfect snuggle buddy for those quiet nights in!"]
  },
  {
    breed: "Rottweiler",
    image: "/Rottweiler.jpg",
    Positive: ["Rotties are super loyal to their humans", "They make excellent watch dogs with their strong protective instincts", "Rotties are great at playing and are known to love zoomies and fetch", "They are big cuddle bugs who love belly rubs and snuggles", "Rotties can be stubborn and require strong training and leadership"]
  },
  {
    breed: "Boxer",
    image: "/Boxer.jpg",
    Positive: ["Boxers are big goofballs, always making you laugh with their silly antics!", "These hounds are great protectors, always watching over their loved ones with a bark and a bite!", "They have endless amounts of energy and love to show off their zoomies and play fetch with their peeps!", "Boxers are total softies at heart, always seeking snuggles and belly rubs from their humans.", "However, Boxers can be stubborn and strong-willed, making training a bit of a challenge at times."]
  },
  {
    breed: "Dobermann",
    image: "/Dobermann.jpg",
    Positive: ["Dobermanns are fiercely loyal to their owners", "Their intelligence makes them quick learners", "Their athletic abilities make them great jogging partners", "They have a great sense of protection and are great guard dogs", "However, they have a tendency to become aggressive if not properly trained and socialized"]
  },
  {
    breed: "Pomeranian",
    image: "/Pomeranian.jpg",
    Positive: ["Pomeranians have fluffy and cute coats that are perfect for snuggles and belly rubs",
    "They have a lot of energy and love to run around and play with their humans, whether it be with fetch or zoomies",
    "Pomeranians are highly intelligent and easy to train, which makes them great for all types of tricks, from fetch to rolling over",
    "These little cuties are known for their love and affection towards their owners and love to be close and give smooches and snuggles",
    "While they are affectionate, they can also become very yappy and bark at anything that seems out of place, so they may not be the best fit for apartment living"]
  },
  {
    breed: "Shih Tzu",
    image: "/Shih-Tzu.jpg",
    Positive: ["Shih Tzus are known for their adorable and cute appearance, with a long and silky coat that is perfect for pampering and petting",
    "They are incredibly loyal and affectionate to their owners and love to be close, whether it be for snuggles or belly rubs",
    "Shih Tzus are easy-going and relaxed dogs that are great for families, as they get along well with both kids and other pets",
    "They are highly adaptable and are happy in both small and large spaces, making them great for city and suburban living",
    "While they are adaptable, they do need regular grooming and maintenance to keep their coat looking healthy, as it can easily mat and tangle."]
  },
  {
    breed: "Cavalier King Charles Spaniel",
    image: "/Cavalier-King-Charles-Spaniel.jpg",
    Positive: ["Cavalier King Charles Spaniels are known for their friendly and affectionate personalities, making them great companions for families and individuals alike",
    "They have a beautiful and soft coat that is perfect for snuggles and petting, and love to be close to their owners",
    "Cavalier King Charles Spaniels are energetic and playful, and love to play fetch and run around with their humans",
    "These pups are highly adaptable and do well in both small and large homes, and are great for city and suburban living",
    "However, they are prone to health issues such as heart problems and ear infections, so regular vet check-ups and care is important for their well-being."]
  },
  {
    breed: "Great Dane",
    image: "/Great-Dane.jpg",
    Positive: ["Great Danes are known for their majestic appearance and gentle personalities, making them great family pets",
    "They are affectionate and loyal to their owners, and love to be close for snuggles and belly rubs",
    "Great Danes are highly energetic and love to play and run, making them great for families with large yards and active lifestyles",
    "They are highly intelligent and easily trained, making them great for tricks and obedience training",
    "While they are great family pets, they do need a lot of space and can be prone to health issues such as hip dysplasia and bloat, so regular vet check-ups and care is important for their well-being."]
  },
  {
    breed: "Bernese Mountain Dog",
    image: "/Bernese-Mountain-Dog.jpg",
    Positive: ["Bernese Mountain Dogs are super friendly and love to give snuggles and smooches to their humans!", "These doggies have the softest fur and love to have their belly rubbed!", "Bernese Mountain Dogs are good with kids and other animals, making them perfect for families!", "These cuties love to go for walkies and play fetch with their human besties!", "One downside of Bernese Mountain Dogs is that they shed a lot and need frequent grooming."]
  },
  {
    breed: "Yorkshire Terrier",
    image: "/Yorkshire-Terrier.jpg",
    Positive: ["Yorkshire Terriers are tiny but mighty pups with a big personality!", "These cuties are super active and love to play, especially with their favorite toys!", "Yorkshire Terriers are very smart and trainable, making them great for tricks and obedience training!", "These little love bugs make great lap dogs and love to snuggle and give smooches to their humans!", "One downside of Yorkshire Terriers is that they can be quite yappy and bark at anything and everything."]
  },
  {
    breed: "Pembroke Welsh Corgi",
    image: "/Pembroke-Welsh-Corgi.jpeg",
    Positive: ["Pembroke Welsh Corgis are friendly and energetic pups that love to play and have fun!", "These doggies are smart and easily trainable, making them great for obedience and agility training!", "Corgis are loyal and protective of their humans, making them great watch dogs!", "These cuties love to be active and go for walkies, runs or play fetch!", "One downside of Pembroke Welsh Corgis is that they are known to have a tendency to become overweight if they don't get enough exercise and eat too many treats."]
  },
  {
    breed: "English Cocker Spaniel",
    image: "/English-Cocker-Spaniel.jpg",
    Positive: ["English Cocker Spaniels are friendly, playful and affectionate pups who love to snuggle!", "These cuties are energetic and love to play, especially with their favorite toys!", "English Cocker Spaniels are known for their exceptional sense of smell and make great hunting dogs!", "These doggies are also great with kids and other animals, making them perfect for families!", "One downside of English Cocker Spaniels is that they require a lot of grooming to keep their long fur healthy and shiny."]
  },
  {
    breed: "Maltese ",
    image: "/Maltese.jpg",
    Positive: ["Maltese are fluffy and cute pups who love to cuddle and give smooches!", "These cuties are energetic and playful, always ready for a good game of fetch!", "Maltese are known for their gentle and affectionate nature, making them great for families!", "These doggies are also great for apartment living as they don't need a lot of space!", "Someone is a bit crusty"]
  },
  {
    breed: "German Shorthaired Pointer",
    image: "/German-Shorthaired-Pointer.jpg",
    Positive: ["German Shorthaired Pointers are energetic and adventurous dogs that love to explore!", "These pups have a great nose for hunting and make great hunting companions!", "German Shorthaired Pointers are intelligent and easily trainable, making them great for obedience and agility training!", "These furry friends love to play and are always up for a good game of fetch!", "One downside of German Shorthaired Pointers is that they have a lot of energy and need plenty of exercise and mental stimulation to keep them happy and healthy."]
  },
  {
    breed: "Boston Terrier",
    image: "/Boston-Terrier.jpg",
    Positive: ["Boston Terriers are the life of the party and love to make their humans laugh!", "These pups are great with kids and other animals and make great family pets!", "Boston Terriers are highly trainable and love to learn new tricks!", "These cuties are great lap dogs and love to snuggle and give smooches to their humans!", "One downside of Boston Terriers is that they can be prone to breathing difficulties due to their short snoot and flat face."]
  },
  {
    breed: "Dalmatian",
    image: "/Dalmatian.jpg",
    Positive: ["Dalmatians are playful pups that love to run and play with their humans!", "These dogs are highly active and make great running or hiking companions!", "Dalmatians are intelligent and trainable, making them great for obedience and agility training!", "These spotted cuties are friendly and outgoing and love to be around people!", "While they love to play and run, Dalmatians can be quite energetic and may be too much for some families to handle."]
  },
  {
    breed: "Basset Hound",
    image: "/Basset-Hound.jpg",
    Positive: ["Basset Hounds are laid-back pups who love to relax and take it slow!", "These dogs have a great sense of smell and make great hunting companions!", "Basset Hounds are friendly and affectionate and love to give snuggles and smooches to their humans!", "These droopy-eared cuties are great with kids and other animals and make great family pets!", "Basset Hounds are prone to weight gain and can be quite lazy, so they need regular exercise to keep them healthy and happy."]
  },
  {
    breed: "Shiba Inu",
    image: "/Shiba-Inu.jpg",
    Positive: ["Shiba Inus are confident and independent dogs that love to explore!", "These pups have a great sense of smell and make great hunting companions!", "Shiba Inus are highly intelligent and trainable, making them great for obedience and agility training!", "These cute little fox-like dogs are friendly and affectionate with their humans!", "Shiba Inus can be quite stubborn and difficult to train, and they may not be the best fit for first-time dog owners."]
  },
  {
    breed: "Miniature Schnauzer",
    image: "/Miniature-Schnauzer.jpg",
    Positive: ["Miniature Schnauzers are spunky and playful pups that love to run and play!", "These dogs are highly intelligent and trainable, making them great for obedience and agility training!", "Miniature Schnauzers are loyal and affectionate with their humans and make great family pets!", "These cute little dogs are great watchdogs and will bark to alert their owners of any potential danger!", "Miniature Schnauzers can be quite vocal and may bark excessively, so they may not be the best fit for apartment living."]
  },
  {
    breed: "Australian Cattle Dog",
    image: "/Australian-Cattle-Dog.jpg",
    Positive: ["Australian Cattle Dogs are energetic and adventurous dogs that love to explore and work!", "These dogs were bred for herding and make great working dogs on the ranch!", "Australian Cattle Dogs are highly trainable and make great obedience and agility dogs!", "These tough and determined dogs are affectionate with their humans and make great family pets!", "Australian Cattle Dogs are highly active and need plenty of exercise and mental stimulation to keep them happy and healthy, they may not be the best fit for a sedentary lifestyle."]
  },
  {
    breed: "Vizsla",
    image: "/Vizsla.jpg",
    Positive: ["Vizslas are energetic and playful dogs that love to run and play!", "These dogs are highly active and make great running or hiking companions!", "Vizslas are highly intelligent and trainable, making them great for obedience and agility training!", "These affectionate dogs are loyal and love to be close to their humans!", "Vizslas are prone to separation anxiety and can become destructive if left alone for long periods of time, so they may not be the best fit for those who work long hours."]
  },
  {
    breed: "Belgian Shepherd",
    image: "/Belgian-Shepherd.jpg",
    Positive: ["Belgian Shepherds are highly intelligent and trainable dogs that love to learn!", "These dogs make great working dogs and excel in obedience and agility training!", "Belgian Shepherds are confident and protective of their humans, making them great guard dogs!", "These versatile dogs are affectionate with their humans and make great family pets!", "Belgian Shepherds are highly active and need plenty of exercise and mental stimulation, they may become destructive if left with too much pent up energy."]
  },
  {
    breed: "Staffordshire Bull Terrier",
    image: "/Staffordshire-Bull-Terrier.jpg",
    Positive: ["Staffordshire Bull Terriers are friendly and affectionate dogs that love to be around their humans!", "These dogs are highly trainable and love to learn new tricks!", "Staffordshire Bull Terriers are great with kids and other animals and make great family pets!", "These strong and muscular dogs have a lot of energy and love to play and run!", "Due to their strong and tenacious nature, Staffordshire Bull Terriers can be prone to aggression if not properly trained and socialized from a young age."]
  },
  {
    breed: "Rhodesian Ridgeback",
    image: "/Rhodesian-Ridgeback.jpg",
    Positive: ["Rhodesian Ridgebacks are strong and athletic dogs that love to go on adventures!", "These pups have a great sense of smell and are great at hunting!", "Rhodesian Ridgebacks are independent and intelligent, making them easy to train!", "These furry friends are affectionate with their humans and love to cuddle and give smooches!", "While they are great at hunting and exploring, Rhodesian Ridgebacks can be quite stubborn and may be difficult to train for some."]
  },
  {
    breed: "American Staffordshire Terrier",
    image: "/American-Staffordshire-Terrier.jpg",
    Positive: ["American Staffordshire Terriers are loyal and protective dogs that love their humans!", "These pups are athletic and love to play and run around with their humans!", "American Staffordshire Terriers are highly trainable and love to learn new tricks!", "These muscular cuties are affectionate and love to cuddle and give smooches to their humans!", "While they are protective of their humans, American Staffordshire Terriers can be wary of strangers and may show aggression towards other dogs and animals."]
  },
  {
    breed: "Portuguese Water Dog",
    image: "/Portuguese-Water-Dog.jpg",
    Positive: ["Boxers are big goofballs, always making you laugh with their silly antics!", "These hounds are great protectors, always watching over their loved ones with a bark and a bite!", "They have endless amounts of energy and love to show off their zoomies and play fetch with their peeps!", "Boxers are total softies at heart, always seeking snuggles and belly rubs from their humans.", "However, Boxers can be stubborn and strong-willed, making training a bit of a challenge at times."]
  },
  {
    breed: "Greyhound",
    image: "/Greyhound.jpg",
    Positive: ["Greyhounds are sleek and graceful dogs that love to run and play!", "These pups are highly athletic and make great running or hiking companions!", "Greyhounds are intelligent and trainable, making them great for obedience and agility training!", "These gentle giants are affectionate and love to cuddle and give smooches to their humans!", "While they love to run and play, Greyhounds can be quite sensitive and may be prone to anxiety and fear."]
  },
  {
    breed: "Pug",
    image: "/Pug.jpg",
    Positive: ["Pugs are playful and energetic pups with a big personality!", "These cuties are great with kids and other animals and make great family pets!", "Pugs are highly trainable and love to learn new tricks!", "These little love bugs make great lap dogs and love to snuggle and give smooches to their humans!", "While they are great with their humans, Pugs can be prone to breathing difficulties due to their short snoot and flat face."]
  },
  {
    breed: "Great Pyrenees",
    image: "/Great-Pyrenees.jpg",
    Positive: ["Great Pyrenees are gentle giants that love to protect their humans!", "These pups are highly intelligent and easily trainable, making them great for obedience and agility training!", "Great Pyrenees are affectionate and love to cuddle and give smooches to their humans!", "These furry friends are great with kids and other animals and make great family pets!", "While they are great protectors, Great Pyrenees can be quite independent and may be difficult to train for some."]
  },
  {
    breed: "Brussels Griffon",
    image: "/Brussels-Griffon.jpg",
    Positive: ["Brussels Griffons are little love bugs that love to give smooches and snuggles to their humans!", "These cute pups are highly social and love to be around people and other animals!", "Brussels Griffons are intelligent and trainable, making them great for tricks and obedience training!", "These little cuties have a big personality and love to make their humans laugh!", "While they love attention and being with their humans, Brussels Griffons can become clingy and may have separation anxiety."]
  },
  {
    breed: "Nova Scotia Duck Tolling Retriever",
    image: "/Nova-Scotia-Duck-Tolling-Retriever.jpg",
    Positive: ["Nova Scotia Duck Tolling Retrievers are playful pups that love to play and retrieve!", "These energetic dogs make great hunting companions and love to work!", "Nova Scotia Duck Tolling Retrievers are highly intelligent and trainable, making them great for obedience and agility training!", "These furry friends are friendly and love to be around people and other animals!", "While they love to play and retrieve, Nova Scotia Duck Tolling Retrievers need plenty of exercise and mental stimulation to keep them happy and healthy."]
  },
  {
    breed: "Newfoundland",
    image: "/Newfoundland.jpg",
    Positive: ["Newfoundlands are gentle giants that love to give snuggles and smooches to their humans!", "These furry friends are highly social and love to be around people and other animals!", "Newfoundlands are highly intelligent and trainable, making them great for obedience and therapy work!", "These big doggies are great swimmers and love to go for swims with their humans!", "While they love to be close to their humans, Newfoundlands can be prone to drooling and slobbering."]
  },
  {
    breed: "Samoyed",
    image: "/Samoyed.jpg",
    Positive: ["Samoyeds are fluffy and friendly pups that love to give snuggles and smooches to their humans!", "These furry friends are highly social and love to be around people and other animals!", "Samoyeds are highly intelligent and trainable, making them great for obedience and agility training!", "These cuties have a great sense of humor and love to make their humans laugh!", "While they love attention and being close to their humans, Samoyeds shed a lot and require frequent grooming."]
  },
  {
    breed: "Saint Bernard",
    image: "/Saint-Bernard.jpg",
    Positive: ["Boxers are big goofballs, always making you laugh with their silly antics!", "These hounds are great protectors, always watching over their loved ones with a bark and a bite!", "They have endless amounts of energy and love to show off their zoomies and play fetch with their peeps!", "Boxers are total softies at heart, always seeking snuggles and belly rubs from their humans.", "However, Boxers can be stubborn and strong-willed, making training a bit of a challenge at times."]
  },
  {
    breed: "Whippet",
    image: "/Whippet.jpg",
    Positive: ["Whippets are fast and energetic pups that love to run and play!", "These athletic dogs make great running or hiking companions!", "Whippets are intelligent and trainable, making them great for obedience and agility training!", "These sleek and slender dogs love to snuggle and give smooches to their humans!", "While they love to run and play, Whippets are sensitive and can be prone to anxiety and shyness."]
  },
  {
    breed: "Chow Chow",
    image: "/Chow-Chow.jpg",
    Positive: ["Chow Chows are regal and dignified dogs that love to snuggle and give smooches to their humans!", "These furry friends are independent and love to have their own space!", "Chow Chows are highly intelligent and trainable, making them great for obedience and agility training!", "These fluffy cuties have a great sense of humor and love to make their humans laugh!", "While they love attention and being close to their humans, Chow Chows can be independent and aloof, and may not enjoy a lot of physical affection."]
  },
  {
    breed: "Otterhound",
    image: "/Otterhound.jpg",
    Positive: ["Otterhounds are friendly and energetic dogs that love to play and explore!", "These athletic dogs make great hiking and hunting companions!", "Otterhounds are intelligent and trainable, making them great for obedience and agility training!", "These furry friends love to snuggle and give smooches to their humans!", "While they love to play and explore, Otterhounds shed a lot and require frequent grooming."]
  },
  {
    breed: "Maremma",
    image: "/Maremma.jpg",
    Positive: ["Maremmas are loyal and protective dogs that love to snuggle and give smooches to their humans!", "These furry friends are highly social and love to be around people and other animals!", "Maremmas are highly intelligent and trainable, making them great for obedience and guard dog training!", "These big doggies love to play and are always up for a good game of fetch!", "While they love to be close to their humans, Maremmas can be prone to barking and may not be suitable for apartment living."]
  },
];

function App() {
  const [dog1Image, setDog1Image] = useState(dogBreeds[0].image);
  const [dog2Image, setDog2Image] = useState(dogBreeds[1].image);
  const [dog1Breed, setDog1Breed] = useState(dogBreeds[0].breed);
  const [dog2Breed, setDog2Breed] = useState(dogBreeds[1].breed);
  const [dog1Positive, setDog1Positive] = useState(dogBreeds[0].Positive);
  const [dog2Positive, setDog2Positive] = useState(dogBreeds[1].Positive);

  function handleButtonClick() {
    const randomIndex1 = Math.floor(Math.random() * dogBreeds.length);
    const randomIndex2 = Math.floor(Math.random() * dogBreeds.length);
    setDog1Image(dogBreeds[randomIndex1].image);
    setDog2Image(dogBreeds[randomIndex2].image);
    setDog1Breed(dogBreeds[randomIndex1].breed);
    setDog2Breed(dogBreeds[randomIndex2].breed);
    setDog1Positive(dogBreeds[randomIndex1].Positive);
    setDog2Positive(dogBreeds[randomIndex2].Positive);
  }

  return (
<html>
<body>
  <div className="container">

    <div className="button-container">
      <button onClick={handleButtonClick}>Next Rounds</button>
    </div>

    <div className="dog-1-container">
      <div className="dog-1-name">{dog1Breed}</div>
      <img src={dog1Image} alt = "Dog 1"/>
      <div className="dog-1-pros">{"Pros"}</div>
      <div className="dog-1-description">{dog1Positive[0]}</div>
      <div className="dog-1-description">{dog1Positive[1]}</div>
      <div className="dog-1-description">{dog1Positive[2]}</div>
      <div className="dog-1-description">{dog1Positive[3]}</div>
      <div className="dog-1-cons">{"Oih Nios"}</div>
      <div className="dog-1-description">{dog1Positive[4]}</div>
    </div>

    <div className="dog-2-container">
      <div className="dog-2-name">{dog2Breed}</div>
      <img src={dog2Image} alt = "Dog 2"/>
      <div className="dog-2-pros">{"Pros"}</div>
      <div className="dog-2-description">{dog2Positive[0]}</div>
      <div className="dog-2-description">{dog2Positive[1]}</div>
      <div className="dog-2-description">{dog2Positive[2]}</div>
      <div className="dog-2-description">{dog2Positive[3]}</div>
      <div className="dog-1-cons">{"Oih Nios"}</div>
      <div className="dog-2-description">{dog2Positive[4]}</div>
    </div>

    

  </div>
</body>
</html>
  );
}

export default App;
